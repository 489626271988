import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const StiftelsenPageTemplate = ({ title, page_name, description, image, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

   const renderFooterAction = page_name => {
    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Styret',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedteker',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
  <main id="index" className="foundation" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
    </Helmet>
    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="mt-12">
        <div className="container main-section">
          <div className="columns">
            <div className="column">
            </div>

            <div className="column is-three-quarters">
              <div className="content has-text-white">
              
              <h1>Stiftelsen</h1>
              <h2>
                Stiftelsen Signe Marie ble opprettet som Åge Lærdal Stiftelsen <br></br> etter en donasjon fra ham 30.12.2005

              </h2>

              <p>
                Stiftelsen er etablert på bakgrunn av det industrieventyr som Åsmund S. Lærdal,  far til donator,<br></br> 
                 skapte – fra produksjon av billedbøker og leketøy til livreddende læremidler og avansert førstehjelpsutstyr.
              </p>

              <p>
                Stiftelsen skiftet navn i 2018 fordi donator ønsket å bære sin eldste søsters navn videre.<br></br>
                Signe Marie døde i august 1942, bare 4 uker gammel, av en sykdom som i dag er enkel å behandle.
              </p>

              <p>
                Spesielle omstendigheter førte til at hun i mange år sjelden ble nevnt. Men hun ble aldri glemt.
              </p>

              <p>
                Signe Marie har nå gitt navn til stiftelsen som har som formål å støtte tiltak for å hindre<br></br>
                barnedødelighet og gi barn en verdig oppvekst.
              </p>
              
              <p>
                Stiftelsen Signe Marie har begrensede administrative ressurser, og mottar <strong>ikke</strong> søknader.
              </p>

              <p>Kontaktperson er <strong>Ida Jaarvik Hetland</strong> (idajaarvikhetland@gmail.com)</p>
              </div>
            </div>

            <div className="column">
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      {renderFooterAction(page_name)}
    </footer>
  </main>
  )
}

StiftelsenPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const StiftelsenPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <StiftelsenPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
      />
    </Layout>
  )
}

StiftelsenPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StiftelsenPage

export const StiftelsenPageQuery = graphql`
  query StiftelsenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
      }
    }
  }
`
